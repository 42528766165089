import React, {
  useRef,
  useEffect,
  useCallback,
  useState,
  useContext,
} from 'react'
import { Spinner } from 'reactstrap'
import { AppContext } from '../../context/AppContext'
import getImageHeaders from '../../utils/imageauth'
import { Image as ImageStyled, Flex } from '../styles/components'

const Image = ({ src, keypath, ...props }) => {
  const context = useContext(AppContext)

  const [loading, setLoading] = useState(true)
  const img = useRef(null)

  const fetching = useCallback(async (src, keypath) => {
    try {
      const res = await fetch(src, {
        headers: {
          Authorization: 'Bearer ' + context.state.token,
        },
      })
      const bytes = await res.blob()
  
      setLoading(false)
      if (img.current) img.current.src = window.URL.createObjectURL(bytes)
    } catch (error) {}
  }, [])

  useEffect(() => {
    fetching(src, keypath)
  }, [src])

  return (
    <>
      {loading ? (
        <Flex justifyContent="center" column>
          <Spinner />
        </Flex>
      ) : (
        <ImageStyled src={''} ref={img} {...props} />
      )}
    </>
  )
}

export default Image
