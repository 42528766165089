import React, { useContext } from 'react'
import { Navbar } from 'reactstrap'
import classnames from 'classnames'
import NavbarBookmarks from './NavbarBookmarks'
import NavbarUser from './NavbarUser'
import userImg from '../../../assets/img/portrait/small/avatar-s-11.jpg'
import { Image } from '../../../components/styles/components'
import { black, white } from '../../../components/styles/styles'
import { history } from '../../../history'
import { AppContext } from '../../../context/AppContext'

const ThemeNavbar = (props) => {
  const colorsArr = ['primary', 'danger', 'success', 'info', 'warning', 'dark']
  const navbarTypes = ['floating', 'static', 'sticky', 'hidden']
  const context = useContext(AppContext)
  return (
    <React.Fragment>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className={classnames(
          'header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow',
          {
            'navbar-light':
              props.navbarColor === 'default' ||
              !colorsArr.includes(props.navbarColor),
            'navbar-dark': colorsArr.includes(props.navbarColor),
            'bg-primary':
              props.navbarColor === 'primary' && props.navbarType !== 'static',
            'bg-danger':
              props.navbarColor === 'danger' && props.navbarType !== 'static',
            'bg-success':
              props.navbarColor === 'success' && props.navbarType !== 'static',
            'bg-info':
              props.navbarColor === 'info' && props.navbarType !== 'static',
            'bg-warning':
              props.navbarColor === 'warning' && props.navbarType !== 'static',
            'bg-dark':
              props.navbarColor === 'dark' && props.navbarType !== 'static',
            'd-none': props.navbarType === 'hidden' && !props.horizontal,
            'floating-nav':
              (props.navbarType === 'floating' && !props.horizontal) ||
              (!navbarTypes.includes(props.navbarType) && !props.horizontal),
            'navbar-static-top':
              props.navbarType === 'static' && !props.horizontal,
            'fixed-top': props.navbarType === 'sticky' || props.horizontal,
            scrolling: props.horizontal && props.scrolling,
          },
        )}
        style={{ backgroundColor: black, color: white }}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div
              className="navbar-collapse d-flex justify-content-between align-items-center"
              id="navbar-mobile"
            >
              <div className="bookmark-wrapper user-nav-list">
                {/* <NavbarBookmarks
                  sidebarVisibility={props.sidebarVisibility}
                  handleAppOverlay={props.handleAppOverlay}
                /> */}
                <ul className="menu-nav">
                  <li
                    style={{ paddingLeft: 0 }}
                    onClick={() => history.push('/request')}
                  >
                    <Image
                      src={require('../../../assets/images/logo.jpg')}
                      width="160px"
                    />
                  </li>
                  <li
                    className={`menu-nav-list ${
                      window.location.pathname.includes('/request')
                        ? 'active'
                        : ''
                    }`}
                    style={{ marginLeft: 50, borderRight: '1px solid #777' }}
                    onClick={() => history.push('/request')}
                  >
                    Pengajuan
                  </li>
                  <li
                    className={`menu-nav-list ${
                      window.location.pathname.includes('/document/manage')
                        ? 'active'
                        : ''
                    }`}
                    onClick={() => history.push('/document/manage')}
                  >
                    File Manager
                  </li>
                  {context.state?.user?.approver_status == 1 ? (
                    <li
                      className={`menu-nav-list ${
                        window.location.pathname.includes('/approval')
                          ? 'active'
                          : ''
                      }`}
                      style={{ borderLeft: '1px solid #777' }}
                      onClick={() => history.push('/approval')}
                    >
                      Approval
                    </li>
                  ) : null}
                </ul>
              </div>
              {props.horizontal ? (
                <div className="logo d-flex align-items-center">
                  <div className="brand-logo mr-50"></div>
                  <h2 className="text-primary brand-text mb-0">Vuexy</h2>
                </div>
              ) : null}
              <NavbarUser
                handleAppOverlay={props.handleAppOverlay}
                changeCurrentLang={props.changeCurrentLang}
                userName="John Doe"
                userImg={userImg}
              />
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  )
}

export default ThemeNavbar
